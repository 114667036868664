import css from "styled-jsx/css";

export const styles = css`
  .SliderButton {
    width: 40px;
    height: 40px;
    background-color: var(--colors-transparent);
    border: 1px solid var(--colors-brandLightGrey2);
    border-radius: var(--border-radius-roundBox);
    outline: none;
    font-size: 10px;
    line-height: 1;
    cursor: pointer;
    transition: border-color 0.2s ease-out;
  }
  .SliderButton:not([disabled]):hover {
    border-color: var(--uiColors-Button-borderedRoundHoverBorder);
  }
  .SliderButton:not([disabled]):active {
    border-color: var(--uiColors-Button-borderedRoundActiveBorder);
  }
  .SliderButton[disabled] {
    color: var(--colors-brandLightGrey8);
    cursor: default;
  }
`;
