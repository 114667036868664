import classNames from "classnames";
import { styles } from "./SliderButton.styles";

export interface Props {
  variant: 'prev' | 'next';
  onClick(): void;
  disabled: boolean;
}

const SliderButton = (props: Props) => {
  const { variant, onClick, disabled } = props;
  return (
    <button
      type='button'
      className={classNames('SliderButton', {
        'icon-arrow-small-left': variant === 'prev',
        'icon-arrow-small-right': variant === 'next',
      })}
      data-marker={variant === 'prev' ? 'Previous slide' : 'Next slide'}
      data-testid={`slider_Button_${variant}`}
      onClick={onClick}
      disabled={disabled}
    >
      <style jsx>{styles}</style>
    </button>
  );
};

export default SliderButton;
