import css from 'styled-jsx/css';

export const styles = css`
  .NavButtons {
    display: flex;
    flex: 0 0 auto;
  }
  .NavButtons__button {
    margin-left: 10px;
  }
`;
