import Responsive from '../../Responsive/Responsive';
import { INavButtonsProps } from '../types';
import { styles } from './NavButtons.styles';
import SliderButton from './SliderButton/SliderButton';

const NavButtons = (props: INavButtonsProps) => {
  const { controls: { onPrevSlide, onNextSlide, sliderState } } = props;
  const { isEndPositionLeft, isEndPositionRight } = sliderState;

  return (
    <Responsive tabletDesktop>
      <div className='NavButtons'>
        <div className='NavButtons__button'>
          <SliderButton
            variant='prev'
            onClick={onPrevSlide}
            disabled={isEndPositionLeft}
          />
        </div>
        <div className='NavButtons__button'>
          <SliderButton
            variant='next'
            onClick={onNextSlide}
            disabled={isEndPositionRight}
          />
        </div>
      </div>

      <style jsx>{styles}</style>
    </Responsive>
  );
};

export default NavButtons;
